
.mb20 {
    margin-bottom: 20px;
}

.mb10 {
    margin-bottom: 20px;
}

.no-shadow {
    box-shadow: none !important;
}
