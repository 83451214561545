html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
  box-sizing: border-box;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* You can add global styles to this file, and also import other style files */
@import 'assets/vendors/global/vendors.bundle.css';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "assets/styles/common";
@font-face {
  font-family: MMTextProTTF;
  src: url(./assets/fonts/MMfonts/MMTextPro-Regular.otf);
}

@font-face {
  font-family: MMTextProBold;
  src: url(./assets/fonts/MMfonts/MMTextPro-Bold.otf);
}

// hide bootstrap dropdown arrow globally
.dropdown-toggle::after {
  display: none !important;
}

.mat-table__wrapper--dashboard .mat-table {
  min-width: 600px !important;
  width: 100% !important;
}

.position-static {
  position: static;
}

.order-confirm .mat-checkbox-layout .mat-checkbox-label {
  white-space: break-spaces !important;
}

.order-confirm .mat-checkbox-inner-container {
  margin: 2px 8px auto 0 !important;
}

.ngx-slider {
  .ngx-slider-selection {
    background: #5867dd !important;
  }
  .ngx-slider-pointer {
    background: #5867dd !important;
    // display: none !important;
    width: 12px !important;
    height: 12px !important;
    border-radius: 6px !important;
    top: -3px !important;
  }
  .ngx-slider-pointer:after {
    background: #5867dd !important;
    width: 12px !important;
    height: 12px !important;
    top: 10px !important;

    display: none !important;
    border-radius: 6px !important;
  }

  .ngx-slider-bubble {
    display: none !important;
  }
}
.required:after {
  content: ' *';
}

//@import '~ng-pick-datetime-ex/assets/style/picker.min.css';

.mat-progress-bar-buffer {
  background-color: #c5cae9 !important;
}
